
.main-card--cointainer {
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 500px));
  grid-gap: 35px;
  justify-content: space-around;
}

.card-container {
  margin: 25px auto 0;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-around;
  align-items: flex-start;
  margin-left:20px;
  margin-right:20px;
}

.card-container img {
  max-width: 100%;
  height: 200px;
  
}

.card {
  background-color: rgb(24,24,24);
  padding-top: 15px;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  border-radius: 10px;
  height: auto;
  box-shadow: 0 0 10px rgba(75, 75, 75, 0.07);
  z-index: 0;
}


.card-title {
  font-size: 30px;
  font-weight: 700;
  margin: 10px 0;
  color: rgb(249,249,249);
  text-align: center;;
}

.card-media {
  display: flex;
  border-radius: 10px;
  width: 100%;
}


@media (max-width: 998px) {
  .main-card--cointainer {
    display: grid;
    grid-template-columns: repeat(1, minmax(200px, 500px));
    grid-gap: 40px;
    justify-content: space-around;
  }
}

