.header-h1{
    color: antiquewhite;
    font-family: 'Roboto Condensed', sans-serif;
    text-align: center;
    text-transform: uppercase;
    /* border-style: solid; */
    margin:auto;
    /* border-radius: 15px; */
    

}

.navbar{
    background-color: rgb(255, 246, 246);
    border-radius: 15px;
    border-style: hidden;
    padding: 18px; 
    text-align: center;
    width: auto;
    top:0;
    z-index: 1;
   
}

.navbar a{
    color: rgb(27, 22, 22);
    text-decoration: none;
    padding: 10px;
    font-family: Arial, Helvetica, sans-serif;
    text-decoration: none;
    color: black;
    font-size: 17px;
    
  
}
.navbar a:hover{
    background-color: #7c7e80;
    border-style: hidden;
    border-radius: 5px;
    color: rgb(255, 254, 253);
}

@media screen and (max-width:500px){
    .navbar{
        background-color: rgb(255, 246, 246);
        border-radius: 15px;
        border-style: hidden;
        padding: 0px; 
        margin: auto;
        text-align: center;
        display: flex;
        flex-direction: row;
        height: 50px;
        padding-bottom: 14px;
        
     }
     #protofolio-link{
        padding-right: 5px;
    }


}
@media screen and (max-width:350px){

    .navbar {
        color: rgb(27, 22, 22);
        text-decoration: none;
        padding: 8px;
        font-family: Arial, Helvetica, sans-serif;
        text-decoration: none;
        color: black;
        font-size: 17px;
        display: flex;
        flex-direction: column;
        height: 200px;
        
      }
      

}

.container-banner{
    background-color: #f1f1f1;
    text-align: center;
    border-style: hidden;
    border-radius: 15px;
    font-family: 'Ruda', sans-serif;
}

#profilepic{
    border-radius: 85px;
    padding-top: 7px;
}

.container-about{
    background-color: rgb(255, 255, 255);
    border-radius: 15px;
    border-style: hidden;
    padding: 18px; 
    text-align: center;
}

.container-about>p{
    font-family: 'Slabo 27px', serif;
    font-size: 20px;
    padding-right: 30px;
    padding-left: 30px;
  

}
.container-about>h1{
    font-family: 'Ruda', sans-serif;
}

.flex-project-container{

    display: flex;
    flex-wrap: wrap;
    justify-content: center;

}
.flex-project-container>div{
    background-color: #f1f1f1;
    width: 150px;
    height: 150px;;
    margin: 10px;
    text-align: center;
    line-height: 75px;
    font-size: 30px;
    border-style: hidden;
    border-radius: 30px;
}

.flex-project-container img{
    margin-top: 27px;
}

.flex-project-container > div:hover
{
transform: scale(1.1);

}

.projects{
    background-color: rgb(255, 255, 255);
    border-radius: 15px;
    border-style: hidden;
    padding: 18px; 
    text-align: center;
    
}
.projects h1{
    font-family: 'Ruda', sans-serif;
}

.projects p{
    font-family: 'Slabo 27px', serif;
    font-size: 20px;
}

.container{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly; 
    flex-wrap: wrap;
}

.container a{
    padding-right: 10px;
    text-decoration: none;
  
}

.container p{
    
    color: black;
    font-family: 'Slabo 27px', serif;
}

.container a:hover
{
transform: scale(1.1);
}

.container-1{
    
    text-align: center;
    font-family: 'Saira Semi Condensed', sans-serif;
    font-size: 16px;     
}

#contactimg{
    border-style: hidden;
    background-color: #f1f1f1;
    border-radius: 90px;
}

.container-1 > a{
    padding-left: 5px;
    font-size: 22px;
    text-decoration: none;
    
}
span:hover{
    text-decoration: none;
    transform: scale(1.5);
    
}

#mail{
    text-decoration: none;
    color: white;
    font-family: 'Saira Semi Condensed', sans-serif;
    font-size: 16px;
    font-weight: bold;
}

#mailmelogo{
    border-style: hidden;
    border-radius:5px;
    width:15px;
    height:15px;
    
}
      

/* .App {
  text-align: center;
} */

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .skillheader{
    color: white;
  }
  .contact{
    color: white;
  }
  .heading{
    text-align: center;
  }
  .imgg{
    border-radius: 10px;
  }


  .channelBottomVideosTitle{
    margin: 10px 0px;
    font-size: 18px;
}

.channelVideos{
    display: flex;
    flex-wrap: wrap;
    gap: 100px;
}